<template>
  <section>
    <div class="form-group">
      <label for="memory_type" style="position: relative">
        {{ $t("memory_type") }}
        <ToolTip :title="hint" />
        <div class="label-right no-select">
          <span
            :class="!localDisabled ? 'clicable' : 'clicable disabled'"
            @click.stop.prevent="
              !localDisabled ? $emit('update:local', !local) : null
            "
            style="position: relative"
          >
            <i :class="local ? 'fa fa-check-square-o' : 'fa fa-square-o'" />
            <span>
              {{ $t("titles.local_data") }}
            </span>
            <ToolTip :title="$t('hints.local_data')" />
          </span>
        </div>
      </label>
      <select
        class="form-control"
        v-model="iValue"
        id="memory_type"
        :disabled="$attrs.disabled"
      >
        <option v-for="(item, ix) in items" :key="ix" :value="item.id">
          {{ item.name }}
        </option>
      </select>
    </div>
    <div
      v-if="previous && parseInt(previous.id) != parseInt(iValue)"
      class="previous"
      :title="`${$t('current_value')}\n${previous.id} - ${previous.name}`"
    >
      <i class="fa fa-save"></i>
      {{ previous.name }}
    </div>
  </section>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
import messages from "@/i18n/data";

export default {
  name: "MemoryTypeSelector",
  i18n: { messages },
  components: {
    ToolTip
  },
  props: {
    memoryTypeList: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: [String, Number],
      required: false,
      default: 0
    },
    local: {
      type: Boolean,
      required: false,
      default: false
    },
    localDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    parser: {
      type: Function,
      required: false,
      default: null
    },
    previous: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {};
  },
  computed: {
    iValue: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value || 0;
      }
    },
    items() {
      return this.parser && this.parser(this.memoryTypeList) || this.memoryTypeList;
    },
    selected() {
      return (
        (this.value &&
          (this?.$root?.config?.references?.data_memory_types || []).find(
            ({ id }) => parseInt(id) == parseInt(this.value)
          )) ||
        null
      );
    },
    hint() {
      return `${this.$t("hints.memory_type")}${this?.selected?.hint ? `<br/>--<p>${this.selected.hint}</p>` : ""
        }`;
    }
  }
};
</script>

<style scoped>
section {
  position: relative;
}

.form-group {
  margin-bottom: 25px;
}

span.disabled {
  color: darkgray;
}

label {
  display: block;
}

div.label-right {
  z-index: 9999;
  position: absolute;
  right: 0;
  top: 0;
}

div.label-right > span > i.fa {
  margin-right: 3px;
}

div.label-right > span.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.previous {
  position: absolute;
  bottom: -18px;
  font-size: 72%;
  text-align: left;
  width: 100%;
  color: rgb(129 80 80);
  padding-left: 2px;
}
</style>